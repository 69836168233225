import ModifiedGdprConsent from "../gdpr/index-modified";

const gdprNode = document.querySelector('[data-component="GdprConsent"]');

if (gdprNode !== null) {
    let props = gdprNode.getAttribute("data-props");
    if (props) props = JSON.parse(props);

    new ModifiedGdprConsent(gdprNode, props);
}
