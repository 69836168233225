import { setCookies } from './helpers'
import GdprConsent from '.';

// Modify setupBtnActions to update ggh_google_maps_terms local storage value on media-cookie
export default class ModifiedGdprConsent extends GdprConsent{
    constructor(rootNode){
        super(rootNode);
    }

    setupBtnActions() {
        this.$refs.buttons.forEach((button) => {
          button.addEventListener('click', () => {
            const action = button.dataset.action
    
            // get all script-ids and save cookie-values
            if (action === 'accept' || action === 'some') {
              // get object with 'scriptId': 'yes|no' values
              const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
                cookies[checkbox.name] = checkbox.checked || action === 'accept' ? 'yes' : 'no'
    
                return cookies
              }, {})
    
              // MODIFICATION
              if(cookies['media'] === 'yes'){
                localStorage.setItem('ggh_google_maps_terms', 'true');
              }
    
              // save cookies
              setCookies(cookies)
    
              // fire events based on cookies and enable gtm
              GdprConsent.enableGtm(this.tagManagerId)
              GdprConsent.fireCookieEvents()
            }
    
            if(action === 'reject') {
    
              const cookies = this.$refs.checkboxes.reduce((cookies, checkbox) => {
                cookies[checkbox.name] = 'no'
    
                return cookies
              }, {})
    
              // MODIFICATION
              if(cookies['media'] === 'yes'){
                localStorage.setItem('ggh_google_maps_terms', 'true');
              }
    
              // save cookies
              setCookies(cookies)
            }
    
            if (this.isModal) this.closeModal()
          })
        })
      }
}